import SpinnerSVG from "./svgs/spinner.svg";
import CloseSVG from "./svgs/close.svg";
import EmailSVG from "./svgs/email.svg";
import FacebookSVG from "./svgs/facebook.svg";
import GoogleSVG from "./svgs/google.svg";
import InstagramSVG from "./svgs/instagram.svg";
import SquigglesSVG from "./svgs/squiggles.svg";
import BracketsSVG from "./svgs/brackets.svg";
import { Icon } from "./Icon";

export const Spinner = Icon(SpinnerSVG);
export const Close = Icon(CloseSVG);
export const Email = Icon(EmailSVG);
export const Facebook = Icon(FacebookSVG);
export const Google = Icon(GoogleSVG);
export const Instagram = Icon(InstagramSVG);
export const Squiggles = Icon(SquigglesSVG);
export const Brackets = Icon(BracketsSVG);
