import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s(
  {
    backgroundColor: Style.colors.white4,
    padding: "30px 20px",
    display: "flex",
    position: "relative",
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      padding: "40px 50px",
    }
  ),
  media(
    { maxWidth: Style.screensize.smMin },
    {
      display: "block",
    }
  )
);

export const imageContainer = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: "70%",
    }
  ),
  media(
    { minWidth: Style.screensize.xlMin },
    {
      width: "65%",
    }
  )
);

export const image = s({
  width: "100%",
});

export const content = s(
  {
    fontFamily: Style.text.font.third,
    backgroundColor: Style.colors.white4,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      position: "absolute",
      top: 55,
      bottom: 55,
      left: "calc(40% + 50px)",
      padding: "15px 40px 15px 30px",
    }
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      left: "calc(44% + 50px)",
      top: 70,
      bottom: 70,
    }
  ),
  media(
    { minWidth: Style.screensize.xlMin },
    {
      left: "calc(40% + 50px)",
      top: 105,
      bottom: 105,
    }
  )
);

export const title = s(
  {
    fontWeight: 900,
    fontStyle: "italic",
    fontSize: Style.text.fontSize.medLarge,
    marginBottom: 20,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 38,
    }
  )
);

export const subTitle = s(
  {
    fontWeight: 600,
    fontSize: Style.text.fontSize.small,
    marginBottom: 15,
    textTransform: "uppercase",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 28,
    }
  )
);

export const details = s(
  {
    fontWeight: 500,
    fontSize: Style.text.fontSize.xs,
    marginBottom: 15,
    lineHeight: "18px",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 14,
      lineHeight: "22px",
    }
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      fontSize: Style.text.fontSize.smallMed,
      lineHeight: "24px",
    }
  ),
  media(
    { minWidth: Style.screensize.xlMin },
    {
      fontSize: Style.text.fontSize.medium,
      lineHeight: "28px",
    }
  )
);

export const buttons = s({
  marginTop: 15,
  display: "flex",
});

export const explore = s(
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 10px",
    fontSize: 14,
    border: "1px solid #111111",
    color: "#111111",
    transition: ".2s ease-in",
    $nest: {
      "&:hover": {
        color: Style.colors.primary,
        borderColor: Style.colors.primary,
      },
    },
    textDecoration: "none",
    borderRadius: 5,
    marginRight: 20,
    textTransform: "uppercase",
    cursor: "pointer",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: "10px 15px",
      fontSize: Style.text.fontSize.smallMed,
    }
  )
);

export const bookNow = s(
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 10px",
    fontSize: 14,
    border: `1px solid ${Style.colors.darkTranslucent650}`,
    backgroundColor: "#111111",
    color: Style.colors.white,
    transition: ".2s ease-in",
    $nest: {
      "&:hover": {
        backgroundColor: Style.colors.primary,
      },
    },
    borderRadius: 5,
    marginRight: 20,
    textTransform: "uppercase",
    cursor: "pointer",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: "10px 15px",
      fontSize: Style.text.fontSize.smallMed,
    }
  )
);

export const logo = s({
  position: "absolute",
  bottom: 0,
  right: 50,
  width: 103,
  zIndex: 4,
});

export const link = s({
  color: "inherit",
  textDecoration: "none",
});
