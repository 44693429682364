import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      minHeight: 800,
      display: "flex",
      justifyContent: "space-between",
    }
  )
);

export const content = s({
  flex: 1,
  lineHeight: 1.8,
  textAlign: "center",
  padding: 30,
});

export const header = s(
  {
    marginBottom: 15,
    color: Style.colors.black3,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginBottom: 30,
      textAlign: "center",
    }
  )
);

export const text = s({
  fontSize: Style.text.fontSize.large,
  color: Style.text.color.subtle,
});
