import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s({
  whiteSpace: "nowrap",
});

export const leftContainer = s(
  {
    display: "inline-block",
    position: "relative",
    height: 500,
    width: 700,
    background: Style.colors.primary,
    boxSizing: "border-box",
    clipPath: "polygon(0 0, 100% 0, 70% 100%, 0% 100%)",
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      height: 600,
      width: 850,
    }
  ),
  media(
    { minWidth: Style.screensize.xlMin },
    {
      width: "60%",
    }
  )
);

export const leftSection = s(
  {
    position: "absolute",
    bottom: 8,
    right: 8,
    height: 492,
    width: 692,
    background: "linear-gradient(180deg, rgba(9, 8, 8, 0.93) 0%, #1C1C1C 100%)",
    boxSizing: "border-box",
    clipPath: "polygon(0 0, 100% 0, 70% 100%, 0% 100%)",
    zIndex: 1,
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      height: 592,
      width: 842,
    }
  ),
  media(
    { minWidth: Style.screensize.xlMin },
    {
      width: "calc(100% - 8px)",
    }
  )
);

export const leftContent = s(
  {
    position: "absolute",
    left: 20,
    top: 40,
    zIndex: 2,
    textAlign: "center",
    color: "#E4E4E4",
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      left: 60,
    }
  )
);

export const logo = s(
  {
    paddingLeft: 50,
    width: 400,
    marginBottom: 15,
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      paddingLeft: 60,
      width: 530,
    }
  )
);

export const title = s(
  {
    background:
      "-webkit-linear-gradient(180deg, rgba(240, 147, 255, 0.94) 0%, #9914AF 100%)",
    "-webkit-text-fill-color": "transparent",
    "-webkit-background-clip": "text !important",
    fontWeight: 800,
    fontStyle: "italic",
    fontSize: Style.text.fontSize.xl,
    fontFamily: Style.text.font.third,
    marginBottom: 20,
    textShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      fontSize: Style.text.fontSize.xxl,
    }
  )
);

export const border = s({
  width: 400,
  height: 2,
  backgroundColor: "#949494",
  margin: `0px auto 20px`,
});

export const details = s(
  {
    fontWeight: 300,
    fontSize: Style.text.fontSize.smallMed,
    lineHeight: "22px",
    whiteSpace: "normal",
    marginBottom: 10,
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      maxWidth: 600,
    }
  )
);

export const smallDetails = s(
  {
    fontWeight: 300,
    fontSize: Style.text.fontSize.small,
    lineHeight: "22px",
    whiteSpace: "normal",
    marginBottom: 20,
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      maxWidth: 600,
    }
  )
);

export const image = s(
  {
    display: "inline-block",
    clipPath: "polygon(30% 0, 100% 0, 100% 99%, 0% 100%)",
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/Octolounge/rehoboth-bay-boat-cruise.png)",
    backgroundSize: "cover",
    height: 500,
    width: 700,
    marginLeft: -210,
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      height: 600,
      width: 850,
      marginLeft: -255,
    }
  ),
  media(
    { minWidth: Style.screensize.xlMin },
    {
      width: "60%",
      marginLeft: "calc(-18%)",
    }
  )
);

export const link = s({
  color: "inherit",
  textDecoration: "none",
});
