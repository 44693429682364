import { useEffect, useContext } from "react";
import { DisplayContext } from "../core/DisplayContext";

export const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement("script");

    const scriptText = document.createTextNode(url);

    script.appendChild(scriptText);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [url]);
};

export const useDisplayContext = () => {
  const context = useContext(DisplayContext);
  return context;
};
