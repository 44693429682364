import * as React from "react";
import * as style from "./Offers.style";
import { IKImage } from "imagekitio-react";
import { classes } from "typestyle";
import { Link } from "react-router-dom";
import { hooks } from "../../utility";
import { Helmet } from "react-helmet";
import { Button } from "../../components";
import sunsetCruise from "../../../public/assets/sunset-cruise.jpg";

const Offers: React.FC = () => {
  const display = hooks.useDisplayContext();
  return (
    <>
      <Helmet>
        <title>Delaware Boat Rentals</title>
        <meta
          name="description"
          content="Delaware Boat Rentals - Every different type of DE boat rental or charter available here. Book Now!"
        />
      </Helmet>
      <div className={style.component}>
        <div className={style.topBorder} />
        <div className={style.section}>
          <IKImage
            className={style.logoLeft}
            alt="The Octolounge Logo"
            path="/logos/octolounge-logo-only.png"
          />
          {!display.isMobile && <div className={style.leftPhoto} />}
          <div className={style.rightContentContainer}>
            <div className={style.rightContentBackground} />
            <div className={style.rightContent}>
              <div className={style.title}>Hourly Charter</div>
              <div className={style.subTitle}>
                2, 3, 4 or 8 Hour Options &bull; All Ages &bull; Private Group
                of Up to 13 People
              </div>
              <div className={style.options}>
                Our most popular options to choose from are:
              </div>
              <div className={style.option}>
                <span style={{ fontWeight: 700 }}>Sandbar Outing: </span>
                Massey’s Landing sandbar, located in the Rehoboth Bay, is where
                the locals hang out on a hot summer day! Spend the day relaxing
                in the shallow waters of the Bay while enjoying music, games and
                our selection of floats to lounge on. Great fun for children and
                adults!
              </div>
              <div className={style.option}>
                <span style={{ fontWeight: 700 }}>Bar Hopping:</span> The
                Octolounge is the Rehoboth Bay charter boat that lets you go bar
                hopping with your own personal “DD”. Our Captains can take you
                from Lewes to Rehoboth to Dewey, anywhere you want to go!
              </div>
              <div className={style.option}>
                <span style={{ fontWeight: 700 }}>Happy Hour:</span> It doesn’t
                get any better than pregaming your night on the town out on the
                water. Book us for Happy Hour and we can drop you back off at
                our slip in downtown Dewey Beach!
              </div>
              <IKImage
                className={style.mobileImage}
                alt="Rehoboth Beach Hourly Cruise"
                path="/rehoboth-bay-boat-cruise.png"
              />
              <Link to="/hourly" className={style.link}>
                <div className={style.moreInfo}>
                  <Button rounded={false}>More Info</Button>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className={style.section}>
          <IKImage
            className={style.logoRight}
            alt="The Octolounge Logo"
            path="/logos/octolounge-logo-only.png"
          />
          <div className={style.leftContentContainer}>
            <div className={style.leftContentBackground} />
            <div className={style.leftContent}>
              <div className={style.leftTitle}>Morning Mimosa Cruise</div>
              <div className={style.leftSubTitle}>
                2 Hours &bull; All Ages &bull; Private Group of Up to 13 People
              </div>
              <div className={style.howItGoes}>How it goes:</div>
              <div className={style.leftDescription}>
                There are not a lot of things more relaxing than a morning on
                the water.
              </div>
              <div className={style.leftDescription}>
                Embrace the morning sun on our Mimosa Cruise! Sip on your
                beverage of choice - whether it's mimosas, coffee, or whatever
                floats your boat. Recline, let go, and absorb the breathtaking
                scenery of the Delaware shores and waterfront real estate. It's
                the perfect way to start your day at the beach!
              </div>
              <IKImage
                className={style.mobileImage}
                alt="Dewey Beach Bachelorette Party Booze Cruise"
                path="/dewey-beach-bachelorette-party-booze-cruise.png"
              />
              <Link to="/morning" className={style.link}>
                <div className={style.moreInfo}>
                  <Button rounded={false}>More Info</Button>
                </div>
              </Link>
            </div>
          </div>

          {!display.isMobile && (
            <IKImage
              className={style.rightPhoto}
              alt="Dewey Beach Bachelorette Party Booze Cruise"
              path="/dewey-beach-bachelorette-party-booze-cruise.png"
            />
          )}
        </div>
        <div className={style.section}>
          <IKImage
            className={style.logoLeft}
            alt="The Octolounge Logo"
            path="/logos/octolounge-logo-only.png"
          />
          {!display.isMobile && (
            <div
              className={classes(style.leftPhoto, style.leftBoozeCruiseImage)}
            />
          )}
          <div
            className={classes(
              style.rightContentContainer,
              style.rightShortContainer
            )}
          >
            <div
              className={classes(
                style.rightContentBackground,
                style.rightShortBackground
              )}
            />
            <div className={style.rightContent}>
              <div className={style.title}>Sunset Cruise</div>
              <div className={style.subTitle}>
                2 Hours &bull; All Ages &bull; Private Group of Up to 13 People
              </div>
              <div className={style.options}>Description:</div>
              <div className={style.option}>
                The summer sunset on the Rehoboth Bay is one of the most
                beautiful sights in Delaware.
              </div>
              <div className={style.option}>
                Rent out the entire boat for your friends and family and get a
                front row seat to watch the sun dip below the horizon
              </div>
              <img
                className={style.mobileImage}
                alt="Dewey Beach Booze Cruise"
                src={sunsetCruise}
              />
              <Link to="/sunset" className={style.link}>
                <div className={style.moreInfo}>
                  <Button rounded={false}>More Info</Button>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className={style.section}>
          {!display.isMobile && (
            <IKImage
              className={style.logoRight}
              alt="The Octolounge Logo"
              path="/logos/octolounge-logo-only.png"
            />
          )}
          <div className={style.leftContainerTall}>
            <div className={style.leftBackgroundTall} />
            <div className={style.leftContent}>
              <div
                className={style.leftTitle}
                style={{ whiteSpace: display.isMobile ? "normal" : "nowrap" }}
              >
                History &amp; Wildlife Tour
              </div>
              <div className={style.leftSubTitle}>
                2 Hours &bull; All Ages &bull; Private Group of Up to 13 People
              </div>
              <div className={style.howItGoes}>
                There are two options for our Historical Tour:
              </div>
              <div className={style.leftDescription}>
                <span style={{ fontWeight: 600 }}>Lewes Tour: </span>We will
                take a ride down the Rehoboth Lewes Canal and then directly
                through the town of Lewes! We can even stop by Kings Creamery
                for some of the best ice cream at the beach!
              </div>
              <div className={style.leftDescription}>
                <span style={{ fontWeight: 600 }}>Indian River Inlet: </span>We
                will cruise through the Rehoboth Bay over to the Inlet while
                talking about the rich history of Delaware’s Inland Bays. During
                the tour we could even stop by the Sandbar for a dip or head
                over to Seaside bar for a cocktail.
              </div>

              <div className={style.howItGoes}>
                We also offer a Wildlife Tour:
              </div>
              <div className={style.leftDescription}>
                Rehoboth Bay is full of all types of Wildlife! Birds such as
                ospreys and bald eagles and marine life like dolphins and
                horseshoe crabs are just a few of the creatures you can expect
                to see out on the water! Come join us for a day of sightseeing
                on the boat!
              </div>
              <IKImage
                className={style.mobileImage}
                alt="Rehoboth Bay History Boat Tour"
                path="/lewes-history-boat-tour.jpg"
              />
              <Link to="/history" className={style.link}>
                <div className={style.moreInfo}>
                  <Button rounded={false}>More Info</Button>
                </div>
              </Link>
            </div>
          </div>
          {!display.isMobile && (
            <>
              <IKImage
                className={style.rightPhotoTall}
                alt="Rehoboth Bay History Boat Tour"
                path="/rehoboth-bay-history-tour.png"
              />
              <IKImage
                className={style.rightPhotoOverlay}
                alt="Rehoboth Beach Historical Cruise"
                path="/rehoboth-canal-history-md.png"
              />
            </>
          )}
        </div>
        <div className={style.section}>
          <IKImage
            className={style.logoLeft}
            alt="The Octolounge Logo"
            path="/logos/octolounge-logo-only.png"
          />
          {!display.isMobile && (
            <div
              className={classes(style.leftPhoto, style.leftDinnerCruiseImage)}
            />
          )}
          <div
            className={classes(
              style.rightContentContainer,
              style.rightShortContainer
            )}
          >
            <div
              className={classes(
                style.rightContentBackground,
                style.rightShortBackground
              )}
            />
            <div className={style.rightContent}>
              <div className={style.title}>Dinner Cruise</div>
              <div className={style.subTitle}>
                2 Hours &bull; All Ages &bull; Private Group of Up to 13 People
              </div>
              <div className={style.options}>Description:</div>
              <div className={style.option}>
                Looking to head into Rehoboth or Lewes for dinner? Maybe even
                Paradise Grille? Why take an uber when you can take the
                Octolounge? Our captain drops you off and picks you up from your
                dinner destination.
              </div>
              <div className={style.option}>
                Grab your friends and family, let us know where you're going to
                dinner, and leave the rest to us! Bring some drinks to have on
                the water to and from your dinner as well! Maybe you'll even
                catch the sunset.
              </div>
              <IKImage
                className={style.mobileImage}
                alt="Lewes Delaware Dinner Cruise"
                path="/lewes-delaware-dinner-cruise.jpg"
              />
              <Link to="/dinner" className={style.link}>
                <div className={style.moreInfo}>
                  <Button rounded={false}>More Info</Button>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Offers.displayName = "Offers";
export { Offers };
