import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    backgroundColor: "#F7F7F7",
    padding: 20,
    borderTop: `3px solid ${Style.colors.darkTranslucent300}`,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      padding: `50px 0px 50px 50px`,
      justifyContent: "flex-start",
    }
  )
);

export const content = s(
  {
    fontWeight: 600,
    fontFamily: Style.text.font.main,
    fontSize: 14,
    color: "#595959",
    lineHeight: "22px",
    marginBottom: 20,
    marginRight: 10,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: 1,
      lineHeight: 1.7,
      fontSize: Style.text.fontSize.small,
    }
  )
);

export const header = s({
  fontFamily: Style.text.font.second,
  fontWeight: 600,
  fontSize: Style.text.fontSize.smallMed,
  marginBottom: 15,
  color: Style.colors.palletPurple,
});

export const routerLink = s(
  {
    textDecoration: "none",
    marginBottom: 5,
    fontFamily: Style.text.font.second,
    fontWeight: 600,
    fontSize: Style.text.fontSize.smallMed,
    color: Style.colors.palletPurple,
  },
  media(
    { maxWidth: Style.screensize.smMax },
    {
      $nest: {
        div: {
          marginBottom: 5,
        },
      },
    }
  )
);

export const logoIcons = s({
  flex: 2,
  textAlign: "left",
});

export const logo = s(
  {
    width: "85%",
    margin: "0px auto 25px auto",
  },
  media(
    { maxWidth: Style.screensize.smMax },
    {
      display: "none",
    }
  )
);

export const iconContainer = s({
  display: "flex",
  justifyContent: "center",
});

export const icon = s({
  display: "flex",
  alignItems: "center",
  width: 50,
  height: 50,
  marginRight: 15,
  $nest: {
    "&:last-child": {
      marginRight: 0,
    },
  },
});

export const secondFooter = s(
  {
    textAlign: "center",
    padding: 20,
    fontFamily: Style.text.font.second,
    fontWeight: 600,
    color: Style.colors.white,
    background:
      "linear-gradient(180deg, #303030 63.02%, rgba(48, 48, 48, 0.86) 100%)",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }
  )
);

export const link = s({
  textDecoration: "none",
  color: Style.colors.black3,
});

export const copyright = s(
  {
    marginBottom: 10,
    fontSize: Style.text.fontSize.xs,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.small,
    }
  )
);

export const allCapsWrapper = s(
  {
    fontSize: Style.text.fontSize.xs,
    fontFamily: Style.text.font.second,
    fontWeight: 800,
    letterSpacing: "2px",
    cursor: "pointer",

    padding: `5px 10px`,
    borderRadius: 16,
    $nest: {
      "&>a": {
        transition: ".2s ease-in",
        color: "inherit",
        textDecoration: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        $nest: {
          "&>div>svg": {
            transition: ".2s ease-in",
          },
          "&:hover": {
            fontSize: Style.text.fontSize.medium,
            color: Style.colors.translucent650,
            $nest: {
              "&>div>svg": {
                fill: Style.colors.translucent650,
                width: 24,
                height: 24,
                stroke: Style.colors.translucent650,
              },
            },
          },
        },
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.smallMed,
    }
  )
);
