import * as React from "react";
import { IKImage } from "imagekitio-react";
import * as style from "./ContactModal.style";
import { Modal } from "../Modal";
import { colors } from "../../styles";
import { hooks } from "../../utility";

interface ComponentProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ContactModal: React.FC<ComponentProps> = ({ isOpen, onRequestClose }) => {
  const display = hooks.useDisplayContext();
  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      className={style.modal}
      closeButtonColor={
        display.isMobile ? colors.translucent650 : colors.darkTranslucent750
      }
    >
      <div className={style.modalDetails}>
        {/* TODO: Update these values */}
        <div className={style.email}>
          <a
            className={style.email}
            href={"email@gmail.com"}
            target="_blank"
            rel="noopener noreferrer"
          >
            info@allcapstechnology.com
          </a>
        </div>
        <IKImage
          path={`/GOC/logo.png`}
          alt="GOC logo"
          loading="lazy"
          className={style.logo}
        />
        <div className={style.phone}>(302) 555-1234</div>
      </div>
    </Modal>
  );
};

ContactModal.displayName = "ContactModal";
export { ContactModal };
