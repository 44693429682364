import * as colors from "./colors";

export const fontSize = {
  /*** 10 */
  xxs: 10,
  /*** 12 */
  xs: 12,
  /*** 16 */
  small: 16,
  /*** 20 */
  smallMed: 20,
  /*** 24 */
  medium: 24,
  /*** 28 */
  medLarge: 28,
  /*** 32 */
  large: 32,
  /*** 40 */
  xl: 40,
  /*** 48 */
  xxl: 48,
  /*** 58 */
  xxxl: 58,
};

export const color = {
  main: colors.black1,
  subtle: colors.darkTranslucent300,
  darkSubtle: colors.darkTranslucent500,
  inverse: colors.white,
  inverseSubtle: colors.translucent650,
};

export const font = {
  /*** Open Sans */
  main: `'Open Sans', sans-serif`,
  /*** Poppins */
  second: "Poppins",
  /*** Montserrat */
  third: "Montserrat",
  /*** Offside */
  fourth: "Offside",
  /*** Old Standard TT */
  fifth: `'Old Standard TT', serif`,
};
