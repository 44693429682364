import * as React from "react";
import { Route, Switch, BrowserRouter, useLocation } from "react-router-dom";
import { Header, Footer } from "../components";
import {
  Home,
  NoMatch,
  Offers,
  Hourly,
  Mimosa,
  History,
  Sunset,
  Faq,
  Dinner,
  Night,
} from "../pages";
import { IKContext } from "imagekitio-react";
import { DisplayContextProvider } from "./DisplayContext";
import { style as s } from "typestyle";
import { BookTab } from "./BookTab";

const component = s({
  width: "100%",
  height: "100%",
  zIndex: 1,
  overflow: "hidden",
});

export const Routes = () => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Switch>
      <Route strict exact={true} path="/" component={Home} />
      <Route strict exact={true} path="/offers" component={Offers} />
      <Route strict exact={true} path="/hourly" component={Hourly} />
      <Route strict exact={true} path="/morning" component={Mimosa} />
      <Route strict exact={true} path="/history" component={History} />
      <Route strict exact={true} path="/sunset" component={Sunset} />
      <Route strict exact={true} path="/dinner" component={Dinner} />
      <Route strict exact={true} path="/night" component={Night} />
      <Route strict exact={true} path="/faq" component={Faq} />
      <Route component={NoMatch} />
    </Switch>
  );
};

export const App = () => (
  <div className={component}>
    <BrowserRouter>
      <DisplayContextProvider>
        <IKContext urlEndpoint="https://ik.imagekit.io/yd113v8urnw4/Octolounge">
          <Header />
          <div style={{ paddingTop: 75 }}>
            <Routes />
          </div>
          <BookTab />
          <Footer />
        </IKContext>
      </DisplayContextProvider>
    </BrowserRouter>
  </div>
);
