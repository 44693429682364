import * as React from "react";
import { Offer } from "./Cruise";
import * as style from "./Cruise.style";
import { Helmet } from "react-helmet";

const MimosaContent: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Bring your Bachelor or Bachelorette party out for a Morning Mimosa Cruise in Dewey Beach / Rehoboth Beach. Book Now!"
        />
        <title>Dewey Beach Bachelor and Bachelorette Party Boat Trip</title>
      </Helmet>
      <div className={style.subHeader}>How it goes:</div>
      <div className={style.details}>
        There are not a lot of things more relaxing than a morning on the water.
        This cruise is popular with Bachelor and Bachelorette parties visiting
        Dewey Beach looking for an early start, and with families on vacation
        looking for a peaceful morning on the Rehoboth Bay!
      </div>
      <div className={style.details}>
        There is plenty of wildlife to see as well as local history to learn
        about! Bring some Mimosas or any drink of choice to get the day started!
      </div>
    </>
  );
};

const Mimosa: React.FC = () => {
  return (
    <Offer
      title="Morning Mimosa Cruise"
      description="2 Hours &bull; All Ages &bull; Private Group of Up to 13 People"
      imageUrl="/dewey-beach-bachelorette-party-booze-cruise.png"
      imageAlt="Dewey Beach Bachelorette Party Booze Cruise"
      children={<MimosaContent />}
    />
  );
};

Mimosa.displayName = "Mimosa";
export { Mimosa };
