import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  whiteSpace: "nowrap",
  fontFamily: Style.text.font.main,
});

export const topBorder = s({
  width: "100%",
  height: 8,
  backgroundColor: Style.colors.primary,
});

export const section = s(
  {
    position: "relative",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginBottom: -6,
    }
  )
);

/** Right Content Sections */

export const leftPhoto = s(
  {
    display: "inline-block",
    position: "relative",
    height: 725,
    width: "60%",
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/Octolounge/rehoboth-bay-boat-cruise.png)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    boxSizing: "border-box",
    clipPath: "polygon(0 0, 70% 0, 100% 100%, 0% 100%)",
    borderBottom: `8px solid ${Style.colors.primary}`,
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      width: "65%",
    }
  )
);

export const rightContentContainer = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      position: "relative",
      background: Style.colors.primary,
      boxSizing: "border-box",
      display: "inline-block",
      marginLeft: "calc(-20%)",
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 30% 100%)",
      width: "60%",
      height: 725,
    }
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      width: "65%",
    }
  )
);

export const rightContentBackground = s(
  {
    position: "absolute",
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/Octolounge/dewey-beach-charter-boat-waves.png)",
    boxSizing: "border-box",
    zIndex: 1,
    backgroundSize: "cover",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      bottom: 8,
      left: 8,
      height: 717,
      width: "calc(100% - 8px)",
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 30% 100%)",
    }
  )
);

export const rightContent = s(
  {
    textAlign: "center",
    zIndex: 2,
    whiteSpace: "normal",
    padding: 20,
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/Octolounge/dewey-beach-charter-boat-waves.png)",
    backgroundSize: "cover",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      backgroundImage: "none",
      width: "90%",
      position: "absolute",
      top: 15,
      right: 10,
    }
  )
);

export const title = s(
  {
    fontFamily: Style.text.font.fourth,
    fontSize: 44,
    marginBottom: 25,
    color: Style.colors.primary,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      color: Style.colors.tertiary,
    }
  )
);

export const subTitle = s(
  {
    fontSize: 16,
    marginBottom: 20,
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      fontSize: 18,
      paddingRight: 100,
    }
  )
);

export const options = s(
  {
    fontSize: 18,
    fontWeight: 600,
    margin: "0px auto 15px",
    textAlign: "left",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      maxWidth: "65%",
      paddingLeft: 60,
    }
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      paddingLeft: 0,
      fontSize: 20,
      maxWidth: "55%",
    }
  )
);

export const option = s(
  {
    fontSize: Style.text.fontSize.small,
    lineHeight: "19px",
    margin: "0px auto 15px",
    textAlign: "left",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      maxWidth: "65%",
      paddingLeft: 60,
    }
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      paddingLeft: 0,
      lineHeight: "22px",
      maxWidth: "55%",
    }
  )
);

/** Left Content Sections */

export const leftContentContainer = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "inline-block",
      position: "relative",
      height: 525,
      width: "59%",
      background: Style.colors.primary,
      boxSizing: "border-box",
      clipPath: "polygon(0 0, 100% 0, 70% 100%, 0% 100%)",
    }
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      width: "65%",
    }
  )
);

export const leftContainerTall = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "inline-block",
      position: "relative",
      height: 725,
      width: "70%",
      background: Style.colors.primary,
      boxSizing: "border-box",
      clipPath: "polygon(0 0, 100% 0, 70% 100%, 0% 100%)",
    }
  )
);

export const leftBackgroundTall = s(
  {
    position: "absolute",
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/Octolounge/dewey-beach-charter-boat-waves.png)",
    backgroundSize: "cover",
    boxSizing: "border-box",
    zIndex: 1,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      bottom: 8,
      right: 8,
      height: 717,
      clipPath: "polygon(0 0, 100% 0, 70% 100%, 0% 100%)",
      width: "calc(100% - 8px)",
    }
  )
);

export const leftContentBackground = s(
  {
    position: "absolute",
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/Octolounge/dewey-beach-charter-boat-waves.png)",
    backgroundSize: "cover",
    boxSizing: "border-box",
    zIndex: 1,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      bottom: 8,
      right: 8,
      height: 517,
      clipPath: "polygon(0 0, 100% 0, 70% 100%, 0% 100%)",
      width: "calc(100% - 8px)",
    }
  )
);

export const leftContent = s(
  {
    zIndex: 3,
    whiteSpace: "normal",
    padding: 20,
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/Octolounge/dewey-beach-charter-boat-waves.png)",
    backgroundSize: "cover",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      backgroundImage: "none",
      position: "absolute",
      left: 40,
      maxWidth: 500,
      top: 20,
    }
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      maxWidth: 550,
      left: 100,
    }
  )
);

export const rightPhoto = s(
  {
    display: "inline-block",
    position: "relative",
    height: 525,
    width: "59%",
    boxSizing: "border-box",
    clipPath: "polygon(30% 0, 100% 0, 100% 100%, 0 100%)",
    borderBottom: `8px solid ${Style.colors.primary}`,
    marginLeft: "calc(-17.7%)",
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      width: "65%",
      marginLeft: "calc(-19.5%)",
    }
  )
);

export const rightPhotoTall = s({
  display: "inline-block",
  position: "relative",
  height: 725,
  width: "70%",
  marginLeft: "calc(-21%)",
  boxSizing: "border-box",
  clipPath: "polygon(30% 0, 100% 0, 100% 100%, 0 100%)",
  borderBottom: `8px solid ${Style.colors.primary}`,
});

export const leftTitle = s(
  {
    fontFamily: Style.text.font.fourth,
    fontSize: 44,
    marginBottom: 25,
    color: Style.colors.primary,
    textAlign: "center",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      color: Style.colors.tertiary,
    }
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      fontSize: 44,
    }
  )
);

export const leftSubTitle = s({
  fontSize: 18,
  marginBottom: 20,
  textAlign: "center",
});

export const howItGoes = s(
  {
    fontSize: 20,
    fontWeight: 600,
    margin: "0px auto 15px",
    textAlign: "left",
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      fontSize: 20,
    }
  )
);

export const leftDescription = s(
  {
    fontSize: Style.text.fontSize.small,
    lineHeight: "22px",
    marginBottom: 15,
    textAlign: "left",
    maxWidth: 430,
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      maxWidth: 525,
    }
  )
);

export const leftBoozeCruiseImage = s({
  backgroundImage:
    "url(https://ik.imagekit.io/yd113v8urnw4/Octolounge/dewey-booze-cruise-lg.png)",
  height: 525,
});

export const leftDinnerCruiseImage = s({
  backgroundImage:
    "url(https://ik.imagekit.io/yd113v8urnw4/Octolounge/lewes-delaware-dinner-cruise.jpg)",
  height: 525,
});

export const rightShortBackground = s({
  height: 517,
});

export const rightShortContainer = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      height: 525,
    }
  )
);

export const rightPhotoOverlay = s({
  position: "absolute",
  top: 0,
  right: 0,
  width: "30%",
});

export const logoLeft = s(
  {
    position: "absolute",
    left: "50%",
    top: 10,
    width: 103,
    zIndex: 4,
  },
  media(
    { maxWidth: Style.screensize.mdMax },
    {
      display: "none",
    }
  )
);

export const logoRight = s(
  {
    position: "absolute",
    left: "53%",
    top: 10,
    width: 103,
    zIndex: 4,
  },
  media(
    { maxWidth: Style.screensize.mdMax },
    {
      display: "none",
    }
  )
);

export const link = s({
  textDecoration: "none",
});

export const moreInfo = s(
  {
    width: "100%",
    textAlign: "center",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginTop: 40,
    }
  )
);

export const mobileImage = s(
  {
    width: "100%",
    marginBottom: 25,
    borderRadius: 4,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "none",
    }
  )
);
