import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const modal = s(
  {
    position: "absolute",
    top: 70,
    left: 0,
    right: 0,
    bottom: 0,
  },
  media(
    { minWidth: Style.screensize.smMin },
    {
      top: 80,
    },
  ),
  media(
    { minWidth: Style.screensize.mdMin },
    {
      top: 100,
      left: 100,
      right: 100,
      bottom: 100,
    },
  ),
);

export const close = s({
  position: "absolute",
  top: 15,
  right: 15,
  zIndex: 50,
  cursor: "pointer",
  $nest: {
    "& svg": {
      fontSize: 25,
      fontWeight: 700,
      transition: ".2s ease-in",
      $nest: {
        "&:hover": {
          fill: Style.colors.darkTranslucent100,
          stroke: Style.colors.darkTranslucent100,
        },
      },
    },
  },
});
