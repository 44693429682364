import * as React from "react";
import * as style from "./Parking.style";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../../utility";
import marina from "../../../../public/assets/marina.jpg";

const Parking: React.FC = () => {
  const display = hooks.useDisplayContext();
  return (
    <div className={style.component}>
      <img
        className={style.image}
        src={marina}
        alt="rehoboth beach charter boat parking"
      />
      <div className={style.content}>
        <IKImage
          className={style.logo}
          alt="The Octolounge Logo"
          path="/logos/octolounge-logo-only.png"
        />
        <div className={style.titleWrapper}>
          <div className={style.title}>Parking Information</div>
          <div className={style.titleLine} />
        </div>
        <div className={style.update}>
          We offer pick up and drop offs in Dewey Beach at the Rehoboth Bay
          Marina. We use the floating pier next to the boat ramp. The marina has
          parking but it is pay to park using the ParkMobile App.{" "}
          <a
            href="https://www.google.com/maps/place/Rehoboth+Bay+Marina/@38.6880719,-75.0775925,17z/data=!3m1!4b1!4m5!3m4!1s0x89b8b61c8e2c49bb:0x44cd4876d26f5427!8m2!3d38.6880677!4d-75.0754038"
            target="_blank"
          >
            Click here for the Google Maps location.
          </a>
        </div>
      </div>
    </div>
  );
};

Parking.displayName = "Parking";
export { Parking };
