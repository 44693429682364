import * as React from "react";
import { Offer } from "./Cruise";
import * as style from "./Cruise.style";
import { Helmet } from "react-helmet";

const HistoryContent: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Come explore the history and wildlife on the Rehoboth Bay! Rehoboth Beach to Lewes informational boat tours. Book Now!"
        />
        <title>Rehoboth Bay History and Wildlife Boat Tour</title>
      </Helmet>
      <div className={style.subHeader}>
        There are two options for our Historical Tour:
      </div>
      <div className={style.details}>
        <span style={{ fontWeight: 600 }}>Lewes Tour: </span>We will take a ride
        down the Rehoboth Lewes Canal and then directly through the town of
        Lewes! We can even stop by Kings Creamery for some of the best ice cream
        at the beach!
      </div>
      <div className={style.details}>
        <span style={{ fontWeight: 600 }}>Indian River Inlet: </span>We will
        cruise through the Rehoboth Bay over to the Inlet while talking about
        the rich history of Delaware’s Inland Bays. During the tour we could
        even stop by the Sandbar for a dip or head over to Seaside bar for a
        cocktail.
      </div>
      <div className={style.subHeader}>We also offer a Wildlife Tour:</div>
      <div className={style.details}>
        Rehoboth Bay is full of all types of Wildlife! Birds such as ospreys and
        bald eagles and marine life like dolphins and horseshoe crabs are just a
        few of the creatures you can expect to see out on the water! Come join
        us for a day of sightseeing on the boat!
      </div>
    </>
  );
};

const History: React.FC = () => {
  return (
    <Offer
      title="History &amp; Wildlife Tour"
      description="2 Hours &bull; All Ages &bull; Private Group of Up to 13 People"
      imageAlt="Rehoboth Beach History Boat Tour"
      imageUrl="/rehoboth-canal-history-md.png"
      children={<HistoryContent />}
    />
  );
};

History.displayName = "History";
export { History };
