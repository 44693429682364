import * as React from "react";
import { classes } from "typestyle";
import * as style from "./Button.style";

export interface ComponentProps
  extends React.ComponentPropsWithoutRef<"button"> {
  /**
   * Optional className to apply to the component
   */
  className?: string;
  /**
   * Function to call when the button is clicked
   */
  onClick?: (event?: any) => void;
  /**
   * Display a loading indicator
   */
  loading?: boolean;
  /**
   * Sets the button to be round
   */
  rounded?: boolean;
  /**
   * Dark Mode
   */
  dark?: boolean;
}

export type Ref = HTMLButtonElement;
/**
 * Button component
 * Wrapper for the button imported component
 */
const Button = React.forwardRef<Ref, ComponentProps>(
  (
    { children, className, disabled, onClick, rounded = true, dark = false },
    ref
  ) => {
    return (
      <button
        className={classes(
          className,
          style.component,
          rounded && style.rounded,
          dark && style.dark
        )}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        type="button"
      >
        {children}
      </button>
    );
  }
);

export { Button };
