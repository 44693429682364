import * as Models from "../models";

export const parseTrip = (raw: Models.RawTrip): Models.Trip => ({
  id: raw.Id,
  userId: raw.UserId,
  displayName: raw.DisplayName,
  startDate: new Date(raw.StartDate),
  endDate: new Date(raw.EndDate),
  comment: raw.Comment,
  destination: raw.Destination,
});

export const parseUser = (raw: Models.RawUserInfo): Models.UserInfo => ({
  ...raw,
  creationTime: new Date(raw.creationTime),
  lastSignInTime: raw.lastSignInTime ? new Date(raw.lastSignInTime) : undefined,
});
