import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s({
  padding: "100px 0px 30px",
  backgroundColor: Style.colors.white,
});

export const reviews = s({
  display: "flex",
  justifyContent: "space-evenly",
  marginBottom: 50,
});
