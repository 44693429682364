import * as React from "react";
import { Link } from "../Link";
import { classes } from "typestyle";
import * as Icons from "../Icon";
import * as style from "./Header.style";
import Modal from "react-modal";
import { MobileHeader } from "./MobileHeader";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../utility";

export interface ComponentProps {
  /**
   * Optional className to apply to the component
   */
  className?: string;
}

/**
 * Header component
 * Page Header
 */
const Header: React.FC<ComponentProps> = ({ className }) => {
  const display = hooks.useDisplayContext();

  return display.isMobile ? (
    <MobileHeader />
  ) : (
    <div className={classes(className, style.component)}>
      <div className={style.contentLeft}>
        <Link to="/">
          <div className={style.logo}>
            <IKImage
              alt="Octolounge Logo"
              className={style.logoImage}
              path="/logos/octolounge-black.png"
              height={60}
              width={129}
            />
          </div>
        </Link>
        <div className={style.logoBorder} />
        <a
          href="https://www.instagram.com/theoctolounge/"
          target="_blank"
          rel="noopener noreferrer"
          className={style.icon}
        >
          <Icons.Instagram size={50} />
        </a>
        <a
          href="https://www.facebook.com/Octolounge"
          target="_blank"
          rel="noopener noreferrer"
          className={style.fbIcon}
        >
          <Icons.Facebook size={40} />
        </a>
        <a
          href="mailto:theoctolounge@gmail.com"
          className={style.icon}
          type="email"
          target="_blank"
        >
          <Icons.Email width={50} height={38} />
        </a>
        <a
          href="https://g.page/r/CaLKJ-UYvONbEBA"
          target="_blank"
          className={style.icon}
        >
          <Icons.Google size={40} />
        </a>
      </div>
      <div className={style.contentRight}>
        <div>
          <Link to={`/`}>
            <div className={style.item}>Home</div>
          </Link>
        </div>
        <div>
          <a
            className={style.link}
            rel="noreferrer"
            href="https://fareharbor.com/embeds/book/theoctolounge/?full-items=yes"
          >
            <div className={style.item}>Book</div>
          </a>
        </div>
        <div>
          <Link to={`/offers`}>
            <div className={style.item}>What We Offer</div>
          </Link>
        </div>
        <div>
          <Link to={`/faq`}>
            <div className={style.item}>FAQs</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

Header.displayName = "Header";
export { Header };
