import * as React from "react";
import * as style from "./Cruise.style";
import { IKImage } from "imagekitio-react";
import { classes } from "typestyle";
import { hooks } from "../../utility";
import { Link } from "react-router-dom";

interface ComponentProps {
  title: string;
  description: string;
  imageUrl: string;
  imageAlt: string;
}

const Offer: React.FC<ComponentProps> = ({
  title,
  description,
  children,
  imageUrl,
  imageAlt,
}) => {
  const display = hooks.useDisplayContext();
  return (
    <div className={style.component}>
      <h1 className={style.title}>{title}</h1>
      <div className={style.description}>{description}</div>
      <div className={style.content}>
        <IKImage
          className={classes(style.section, style.image)}
          path={imageUrl}
          alt={imageAlt}
        />
        <div className={style.section}>
          <div style={{ marginBottom: 30 }}>{children}</div>
          <a
            href="https://fareharbor.com/embeds/book/theoctolounge/?full-items=yes"
            rel="noreferrer"
            className={style.link}
          >
            <div className={style.bookButton}>Book Now</div>
          </a>
        </div>
      </div>
      <div className={style.content}>
        <div className={style.section}>
          <div className={style.subHeader}>About the boat:</div>
          <div className={style.details}>
            The Octolounge is a 26ft 2015 PalmBeach Ultra Sport Tritoon with a
            250 Yamaha outboard.
          </div>
          <div className={style.details}>
            The boat has a top of the line Jensen Stereo System and a Garmin GPS
            along with party lights once the sun goes down.
          </div>
          <div className={style.details}>
            In order to stay safe at night it is also equipped with a spotlight
            on the bow.
          </div>
          <div className={style.details}>
            The boat is also equipped with a floating water mat, beach chairs,
            sandbar games, a deck of cards, a small cooler with water, a big
            cooler for your beverage of choice, a table, and a trash can.
          </div>
        </div>
        <div className={style.section}>
          <div className={style.subHeader}>What to bring:</div>
          <div className={style.details}>
            This cruise is BYOB. Bring your own booze.
          </div>
          <div className={style.details}>
            We have a 54 quart cooler and ice on board so just bring your
            beverage of choice, sunscreen, towels, and clothing that you don't
            care about getting wet. We will be on a boat.
          </div>
          <Link to="/offers" className={style.link}>
            <div className={classes(style.bookButton, style.seeMore)}>
              See More Options
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

Offer.displayName = "Offer";
export { Offer };
