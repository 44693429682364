import * as React from "react";
import { style as s } from "typestyle";
import { text, colors } from "../styles";
import { Button } from "../components";
import { hooks } from "../utility";

const book = s({
  textDecoration: "none",
});

const button = s({
  fontFamily: text.font.main,
  transform: "rotate(90deg)",
  position: "fixed",
  right: -50,
  top: "60%",
  height: 50,
  fontSize: 18,
  fontWeight: 500,
  backgroundColor: colors.secondary,
  zIndex: 1000,
  $nest: {
    "&:hover": {
      backgroundColor: colors.black1,
    },
  },
  textTransform: "uppercase",
});
const BookTab: React.FC = () => {
  const display = hooks.useDisplayContext();
  return display.isMobile ? (
    <a
      className={book}
      rel="noreferrer"
      href="https://fareharbor.com/embeds/book/theoctolounge/?full-items=yes"
    >
      <Button className={button}>Book Now</Button>
    </a>
  ) : null;
};

BookTab.displayName = "BookTab";
export { BookTab };
