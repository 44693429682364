import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  background: "#F7F7F7",
  zIndex: 10,
  padding: "7px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: Style.text.font.main,
  borderBottom: `1px solid #B9B9B9`,
});

export const contentLeft = s({
  position: "relative",
  display: "flex",
  alignItems: "center",
  height: "100%",
});

export const contentRight = s({
  position: "relative",
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "100%",
  justifyContent: "flex-end",
  paddingRight: 10,
});

export const logo = s({
  width: 220,
  height: 60,
});

export const logoImage = s({
  width: 129,
  height: 60,
});

export const logoBorder = s({
  position: "absolute",
  left: 180,
  top: -20,
  bottom: -12,
  width: 1,
  backgroundColor: "#B9B9B9",
  transform: "rotate(30deg)",
});

export const icon = s({
  display: "flex",
  alignItems: "center",
  width: 50,
  height: 50,
  marginRight: 20,
  cursor: "pointer",
});

export const fbIcon = s({
  display: "flex",
  alignItems: "center",
  width: 50,
  height: 50,
  marginRight: 12,
});

export const item = s({
  color: Style.text.color.main,
  fontWeight: 300,
  marginRight: 25,
  fontSize: Style.text.fontSize.smallMed,
  $nest: {
    "&:hover": {
      color: Style.text.color.subtle,
    },
  },
  textTransform: "uppercase",
});

export const mobileContent = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "none",
    }
  )
);

export const left = s({
  display: "flex",
  alignItems: "center",
  flex: 1,
});

export const right = s({
  flex: 1,
  display: "flex",
  justifyContent: "flex-end",
  $nest: {
    "&>*": {
      marginRight: 20,
    },
  },
});

export const link = s({
  textDecoration: "none",
  color: Style.colors.black3,
});

export const modalStyles = {
  content: {
    top: "20%",
    left: "30px",
    bottom: "20%",
    right: "30px",
  },
  overlay: {
    zIndex: 1000,
  },
};

export const mobileItem = s({
  marginBottom: 20,
  width: "100%",
});

export const mobileButton = s({
  width: "100%",
});

export const more = s({
  display: "flex",
  alignItems: "center",
  flex: 1,
  height: "100%",
  marginRight: 30,
});
