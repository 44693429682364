import * as React from "react";
import { classes } from "typestyle";
import * as style from "./Modal.style";
import ReactModal from "react-modal";
import { Close } from "../Icon";
import { animated, useSpring, config } from "react-spring";
import { colors } from "../../styles";
export interface ComponentProps {
  isOpen: boolean;
  className?: string;
  onRequestClose?: () => void;
  showCloseButton?: boolean;
  closeButtonColor?: string;
  disableAnimation?: boolean;
}

export const Modal: React.FC<ComponentProps> = ({
  isOpen,
  onRequestClose,
  className,
  children,
  showCloseButton = true,
  disableAnimation = false,
  closeButtonColor,
}) => {
  const anim = useSpring({
    config: config.stiff,
    from: { opacity: 0, transform: "translateY(500px)" },
    to: {
      opacity: 1,
      transform: "translateY(0px)",
    },
    reset: true,
  });
  return isOpen ? (
    <ReactModal
      onRequestClose={onRequestClose}
      isOpen={true}
      className={classes(className, style.modal)}
      style={{
        overlay: {
          zIndex: 1000,
        },
      }}
      ariaHideApp={false}
    >
      <animated.div style={!disableAnimation ? anim : {}}>
        {showCloseButton && (
          <div className={style.close}>
            <Close
              onClick={onRequestClose}
              fill={closeButtonColor || colors.darkTranslucent250}
              stroke={closeButtonColor || colors.darkTranslucent250}
              size={25}
            />
          </div>
        )}
        {children}
      </animated.div>
    </ReactModal>
  ) : null;
};
