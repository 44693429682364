import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s(
  {
    padding: 20,
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/Octolounge/rehoboth-bay-waves.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642172737162)",
    backgroundSize: "cover",
    position: "relative",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: 45,
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "flex-end",
      flexWrap: "nowrap",
    }
  )
);

export const image = s(
  { width: "100%" },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: "80%",
    }
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      width: "70%",
    }
  )
);

export const content = s(
  {
    padding: `10px 15px`,
    fontFamily: Style.text.font.third,
    zIndex: 2,
    maxWidth: 600,
    backgroundColor: Style.colors.white3,
    maxHeight: "calc(100% - 160px)",
    position: "relative",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginRight: -300,
      padding: 30,
    }
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      marginRight: -250,
    }
  )
);

export const titleWrapper = s(
  {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15,
    paddingRight: 20,
    alignItems: "center",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginBottom: 40,
    }
  )
);

export const title = s(
  {
    color: "#3F1546",
    fontWeight: 800,
    fontStyle: "italic",
    fontSize: Style.text.fontSize.medium,
    textTransform: "uppercase",
    marginRight: 20,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.medLarge,
    }
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      fontSize: Style.text.fontSize.large,
    }
  )
);

export const titleLine = s({
  height: 2,
  flex: 1,
  backgroundColor: Style.colors.primary,
});

export const details = s(
  {
    fontWeight: 500,
    fontSize: Style.text.fontSize.xs,
    marginBottom: 15,
    lineHeight: "18px",
    color: "#242424",
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      fontSize: Style.text.fontSize.small,
    }
  )
);

export const update = s(
  {
    fontWeight: 700,
    fontSize: 14,
    marginBottom: 15,
    lineHeight: "20px",
    color: "#141414",
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      fontSize: 18,
      lineHeight: "24px",
    }
  )
);

export const logo = s(
  {
    position: "absolute",
    bottom: -20,
    left: -60,
    width: 103,
    zIndex: 4,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      left: -40,
    }
  )
);
