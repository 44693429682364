import * as React from "react";
import * as style from "./Reviews.style";
import { IKImage } from "imagekitio-react";

const Reviews: React.FC = () => {
  return (
    <div className={style.component}>
      <div className={style.reviews}>
        <IKImage
          alt="dewey beach family boat charter review"
          path="/dewey-family-boat-charter-review.png"
          width={356}
          height={243}
        />
        <IKImage
          alt="rehoboth bay boat tour review"
          path="/rehoboth-bay-boat-tour-review.png"
          width={370}
          height={176}
        />
        <IKImage
          alt="dewey pontoon boat rental review"
          path="/dewey-pontoon-boat-rental.png"
          width={323}
          height={239}
        />
      </div>
    </div>
  );
};

Reviews.displayName = "Reviews";
export { Reviews };
