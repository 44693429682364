import * as React from "react";
import * as style from "./Title.style";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../../utility";
import { Button } from "../../../components";
import { MobileTitle } from "./MobileTitle";

const Title: React.FC = () => {
  const display = hooks.useDisplayContext();
  return display.isMobile ? (
    <MobileTitle />
  ) : (
    <div className={style.component}>
      <div className={style.leftContainer}>
        <div className={style.leftSection} />
        <div className={style.leftContent}>
          <div className={style.logo}>
            <IKImage
              alt="Octolounge Logo"
              path="/logos/octolounge-white.png"
              width={
                display.viewport === "md"
                  ? 400
                  : display.viewport === "lg"
                  ? 460
                  : 530
              }
            />
          </div>
          <div className={style.title}>Private Boat Rentals</div>
          <div className={style.border} />
          <div className={style.details}>
            No vacation is complete without a day on the water!
          </div>
          <div className={style.smallDetails}>
            Rehoboth Beach - Dewey Beach - Lewes
          </div>
          <a
            className={style.link}
            rel="noreferrer"
            href="https://fareharbor.com/embeds/book/theoctolounge/?full-items=yes"
          >
            <Button rounded={false}>Book Now</Button>
          </a>
        </div>
      </div>
      <div className={style.image} />
    </div>
  );
};

Title.displayName = "Title";
export { Title };
