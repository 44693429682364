import * as React from "react";
import { Offer } from "./Cruise";
import * as style from "./Cruise.style";
import { Helmet } from "react-helmet";

const DinnerContent: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Ride the Octolounge through southern Delaware on a Dinner Cruise to and from Lewes, Rehoboth, or Paradise Grill. Book Now!"
        />
        <title>Dinner Cruise on Rehoboth Bay - Lewes, Dewey, Rehoboth</title>
      </Helmet>
      <div className={style.subHeader}>How it goes:</div>
      <div className={style.details}>
        Looking to head into Rehoboth or Lewes for dinner? Maybe even Paradise
        Grille? Why take an uber when you can take the Octolounge? Our captain
        drops you off and picks you up from your dinner destination.
      </div>
      <div className={style.details}>
        Grab your friends and family, let us know where you're going to dinner,
        and leave the rest to us! Bring some drinks to have on the water to and
        from your dinner as well! Maybe you'll even catch the sunset.
      </div>
    </>
  );
};

const Dinner: React.FC = () => {
  return (
    <Offer
      title="Dinner Cruise"
      description="2 Hours &bull; All Ages &bull; Private Group of Up to 13 People"
      imageUrl="/lewes-delaware-dinner-cruise.jpg"
      imageAlt="Lewes Delaware Dinner Cruise"
      children={<DinnerContent />}
    />
  );
};

Dinner.displayName = "Dinner";
export { Dinner };
