import * as React from "react";
import { Offer } from "./Cruise";
import * as style from "./Cruise.style";

const NightContent: React.FC = () => {
  return (
    <>
      <div className={style.subHeader}>How it goes:</div>
      <div className={style.details}>
        Don’t feel like waiting in lines and dealing with crowds in the bars at
        night? Come out with us on the water for Twilight! We will take a slow
        cruise around the bay and down the Lewes canal for a relaxing night on
        the water.
      </div>
      <div className={style.details}>
        The boat has a top of the line Jensen Stereo System and party lights for
        once the sun goes down. In order to stay safe at night it is also
        equipped with a spotlight on the bow.
      </div>
    </>
  );
};

const Night: React.FC = () => {
  return (
    <Offer
      title="Night Cruise"
      description="2 Hours &bull; All Ages &bull; Private Group of Up to 13 People"
      imageUrl="/delaware-night-boat-rental.jpg"
      imageAlt="Dewey Beach Night Cruise"
      children={<NightContent />}
    />
  );
};

Night.displayName = "Night";
export { Night };
