import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const modal = s(
  {
    backgroundColor: Style.colors.tertiary,
    opacity: 0.8,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      opacity: 1,
      margin: "auto",
      maxHeight: "fit-content",
      maxWidth: "fit-content",
      backgroundColor: Style.colors.translucent900,
      borderRadius: 15,
      padding: `20px 40px`,
      left: "auto",
      right: "auto",
    },
  ),
);

export const modalDetails = s(
  { textAlign: "center", padding: "100px 20px" },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      alignItems: "center",
      padding: 0,
    },
  ),
);

export const logo = s(
  {
    width: 109,
    height: 100,
    transition: "all .3s ease-out",
    margin: `0px 40px`,
  },
  media(
    { maxWidth: Style.screensize.smMax },
    {
      marginBottom: 35,
    },
  ),
);
export const phone = s(
  {
    letterSpacing: 3,
    fontWeight: 700,
    fontSize: Style.text.fontSize.medLarge,
    color: Style.text.color.inverse,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      textAlign: "left",
      width: "25vw",
      color: Style.text.color.main,
    },
  ),
);

export const email = s(
  {
    width: "25vw",
    fontWeight: 600,
    letterSpacing: 0,
    color: Style.colors.tertiary,
    fontSize: Style.text.fontSize.smallMed,
    textAlign: "right",
  },
  media(
    { maxWidth: Style.screensize.smMax },
    {
      width: "auto",
      textAlign: "center",
      marginBottom: 35,
      color: Style.text.color.inverse,
    },
  ),
);
