import * as React from "react";
import * as style from "./NoMatch.style";
import { Link } from "react-router-dom";

const NoMatch: React.FC = () => {
  return (
    <div className={style.component}>
      <div className={style.content}>
        <div className={style.header}>
          We Cannot Find The Page You Are Looking For
        </div>
        <div className={style.text}>
          <Link to="/">Click Here</Link> to learn more about The Octolounge
        </div>
      </div>
    </div>
  );
};

NoMatch.displayName = "NoMatch";
export { NoMatch };
