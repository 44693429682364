import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s(
  {
    fontFamily: Style.text.font.second,
    backgroundColor: Style.colors.white3,
    minHeight: 950,
    maxWidth: 1400,
    margin: "auto",
  },
  media(
    { maxWidth: Style.screensize.smMax },
    {
      backgroundImage:
        "url(https://ik.imagekit.io/yd113v8urnw4/Octolounge/dewey-beach-charter-boat-waves.png)",
      backgroundSize: "cover",
    }
  )
);

export const titleContainer = s(
  {
    width: "100%",
    height: 75,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      height: 123,
    }
  )
);

export const titleImage = s({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
  width: "100%",
});

export const title = s(
  {
    fontSize: 40,
    fontWeight: 900,
    fontFamily: Style.text.font.second,
    textTransform: "uppercase",
    zIndex: 2,
    color: Style.colors.white,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 52,
    }
  )
);

export const contentContainer = s(
  {
    padding: `20px 10px`,
    textAlign: "center",
    position: "relative",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: 40,
    }
  )
);

export const squiggles = s(
  {
    position: "absolute",
    top: 120,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      top: 80,
    }
  )
);

export const squiggles2 = s(
  {
    position: "absolute",
    top: 520,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      top: 600,
    }
  )
);

export const contentOverlay = s(
  {
    background:
      "linear-gradient(180deg, rgba(168, 23, 192, 0.62) 0%, #A817C0 100%)",
    width: "100%",
    left: 0,
    position: "absolute",
    top: 100,
    bottom: 0,
    clipPath: "polygon(0 0, 100% 83%, 100% 100%, 0% 100%)",
    zIndex: 3,
  },
  media(
    { maxWidth: Style.screensize.smMax },
    {
      display: "none",
    }
  )
);

export const cruises = s({
  display: "flex",
  justifyContent: "space-evenly",
  flexWrap: "wrap",
});

export const cruise = s(
  {
    padding: `20px 20px 40px`,
    background: `linear-gradient(180deg, ${Style.colors.translucent650}, ${Style.colors.translucent400} 100%)`,
    zIndex: 4,
    margin: `0px 10px 10px 0px`,
    flex: "1 0 30",
    position: "relative",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      minHeight: 475,
      maxWidth: "26%",
      margin: `0px 0px 10px 0px`,
      padding: "35px 25px",
      background:
        "linear-gradient(180deg, #FCFCFC 0%, rgba(234, 234, 234, 0.99) 100%)",
    }
  )
);
export const cruiseTitle = s(
  {
    fontWeight: 500,
    lineHeight: "25px",
    fontSize: Style.text.fontSize.medium,
    color: Style.colors.primary,
    minHeight: 45,
    borderBottom: "1px solid black",
    marginBottom: 20,
    marginRight: 10,
    textTransform: "uppercase",
    zIndex: 2,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.medLarge,
      marginRight: 0,
      minHeight: 60,
      marginBottom: 10,
    }
  )
);
export const cruiseDetails = s(
  {
    fontSize: Style.text.fontSize.small,
    marginBottom: 20,
    maxWidth: 500,
    margin: "auto",
    textAlign: "left",
    zIndex: 2,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginBottom: 0,
      minHeight: 100,
    }
  )
);

export const bullets = s(
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: 0,
    zIndex: 2,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flexDirection: "row",
      minHeight: 90,
    }
  )
);

export const shortList = s(
  {
    paddingLeft: 20,
    display: "flex",
    justifyContent: "center",
    zIndex: 2,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      justifyContent: "flex-start",
      minHeight: 90,
    }
  )
);
export const hourlyBullet = s(
  {
    fontSize: 14,
    marginBottom: 5,
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      margin: `0px 25px 15px 0px`,
    }
  )
);
export const bullet = s({
  textAlign: "left",
});

export const imageContainer = s({
  position: "relative",
  minHeight: 200,
  marginBottom: 20,
});

export const image = s(
  {
    height: "100%",
    width: "100%",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      height: "auto",
      maxHeight: 220,
      objectFit: "cover",
    }
  )
);

export const link = s({
  textDecoration: "none",
  color: Style.colors.tertiary,
});
