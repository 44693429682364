import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s({
  backgroundSize: "cover",
  backgroundImage:
    "url(https://ik.imagekit.io/yd113v8urnw4/Octolounge/rehoboth-bay-boat-cruise.png)",
  boxSizing: "border-box",
  textAlign: "center",
  borderBottom: `8px solid ${Style.colors.primary}`,
});

export const content = s({
  background: `linear-gradient(to bottom, ${Style.colors.translucent650}, ${Style.colors.translucent900} 80%)`,
  padding: `30px 20px`,
});

export const title = s({
  color: Style.colors.primary,
  fontWeight: 800,
  fontStyle: "italic",
  fontSize: Style.text.fontSize.medLarge,
  marginBottom: 20,
  textTransform: "uppercase",
});

export const border = s({
  width: 200,
  height: 2,
  backgroundColor: "#949494",
  margin: `0px auto 20px`,
});

export const details = s(
  {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "24px",
    whiteSpace: "normal",
    color: Style.text.color.main,
    margin: "auto",
  },
  media(
    { minWidth: Style.screensize.smMin },
    {
      fontSize: Style.text.fontSize.small,
      lineHeight: "28px",
    }
  )
);

export const smallDetails = s(
  {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "22px",
    whiteSpace: "normal",
    color: Style.text.color.main,
    margin: "auto",
  },
  media(
    { minWidth: Style.screensize.smMin },
    {
      fontSize: Style.text.fontSize.small,
      lineHeight: "28px",
    }
  )
);
