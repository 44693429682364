import * as React from "react";
import * as style from "./Fun.style";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../../utility";
import { Link } from "react-router-dom";

const Fun: React.FC = () => {
  const display = hooks.useDisplayContext();
  return (
    <div className={style.component}>
      <div className={style.imageContainer}>
        <IKImage
          className={style.image}
          alt="rehoboth bay booze cruise"
          path="/paradise-grill-pontoon-boat.png"
        />
      </div>
      <div className={style.content}>
        {!display.isMobile && (
          <IKImage
            className={style.logo}
            alt="The Octolounge Logo"
            path="/logos/octolounge-logo-only.png"
          />
        )}
        <div className={style.title}>Fun begins here.</div>
        <div className={style.subTitle}>
          Your own customizable day on the water
        </div>
        <div className={style.details}>
          The only private boat charter experience in the area that allows you
          to do everything that the Rehoboth Bay has to offer. It is your own
          customizable day on the water with an experienced Captain.
        </div>
        <div className={style.details} style={{ marginBottom: 30 }}>
          Whether you’d like to hang out at the Sandbar for the day with the
          locals or bar hop from Irish Eyes in Lewes to Paradise Grill in
          Longneck, the Octolounge offers you the flexibility to choose what you
          would like to do!
        </div>
        <div className={style.buttons}>
          <Link to="/offers" className={style.explore}>
            <div>Explore</div>
          </Link>
          <a
            className={style.link}
            rel="noreferrer"
            href="https://fareharbor.com/embeds/book/theoctolounge/?full-items=yes"
          >
            <div className={style.bookNow}>Book Now</div>
          </a>
        </div>
      </div>
    </div>
  );
};

Fun.displayName = "Fun";
export { Fun };
