import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    padding: `20px 10px`,
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/Octolounge/dewey-beach-charter-boat-waves.png)",
    backgroundSize: "cover",
    fontFamily: Style.text.font.main,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: `60px 80px`,
    }
  )
);

export const title = s(
  {
    fontFamily: Style.text.font.fourth,
    fontSize: 40,
    marginBottom: 40,
    color: Style.colors.tertiary,
    textAlign: "center",
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      fontSize: 44,
    }
  )
);

export const content = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
    }
  )
);

export const section = s({
  flex: 1,
  paddingRight: 40,
});

export const question = s({
  fontSize: 24,
  marginBottom: 15,
  fontWeight: 500,
});

export const answer = s({
  fontSize: 20,
  marginBottom: 30,
});
