import * as React from "react";

import { Link } from "../Link";
import { IKImage } from "imagekitio-react";
import { Burger } from "../Burger";
import * as style from "./MobileHeader.style";
import { colors } from "../../styles";
import { Modal } from "../Modal";
import { useLocation } from "react-router-dom";

export interface ComponentProps {
  className?: string;
}
/**
 * Header component
 * Page Header
 */
const MobileHeader: React.FC<ComponentProps> = ({ className }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const location = useLocation();
  React.useEffect(() => {
    setMenuOpen(false);
  }, [location]);
  return (
    <>
      <div className={style.component} id="header">
        <Link to="/">
          <IKImage
            path={`/logos/octolounge-black.png`}
            alt="Octolounge logo"
            className={style.logo}
            height={65}
          />
        </Link>
        <Burger
          isOpen={menuOpen}
          onClick={() => setMenuOpen(!menuOpen)}
          color={colors.primary}
          className={style.burger}
        />
      </div>
      <Modal
        isOpen={menuOpen}
        onRequestClose={() => setMenuOpen(false)}
        className={style.modal}
        showCloseButton={false}
      >
        <Link to="/" className={style.link}>
          <div className={style.navItem}>Home</div>
        </Link>
        <a
          className={style.link}
          rel="noreferrer"
          href="https://fareharbor.com/embeds/book/theoctolounge/?full-items=yes"
        >
          <div className={style.navItem}>Book</div>
        </a>
        <Link to="/offers" className={style.link}>
          <div className={style.navItem}>What We Offer</div>
        </Link>
        <Link to="/faq" className={style.link}>
          <div className={style.navItem}>FAQ</div>
        </Link>
      </Modal>
    </>
  );
};

MobileHeader.displayName = "MobileHeader";

export { MobileHeader };
