import * as React from "react";
import * as style from "./Home.style";
import { Title } from "./Title";
import { Fun } from "./Fun";
import { LearnMore } from "./LearnMore";
import { Reviews } from "./Reviews";
import { Parking } from "./Parking";
import { hooks } from "../../utility";
import { Helmet } from "react-helmet";

const Home: React.FC = () => {
  const display = hooks.useDisplayContext();
  return (
    <div className={style.component}>
      <Helmet>
        <meta
          name="description"
          content="Southern Delaware Boat Rentals in Rehoboth and Dewey Beach - Hourly Charters, Booze Cruises, Night Cruises, and more - Book Now!"
        />
        <title>Rehoboth Beach Boat Rentals | Dewey Beach Booze Cruises</title>
      </Helmet>
      <Title />
      <Fun />
      <LearnMore />
      {!display.isMobile && <Reviews />}
      <Parking />
    </div>
  );
};

Home.displayName = "Home";
export { Home };
