import * as React from "react";
import * as style from "./Faq.style";

const Faq: React.FC = () => {
  return (
    <div className={style.component}>
      <h1 className={style.title}>Frequently Asked Questions</h1>
      <div className={style.content}>
        <div className={style.section}>
          <div className={style.question}>Is Alcohol included?</div>
          <div className={style.answer}>
            No! Every cruise is BYOB. Bring your own booze.
          </div>
          <div className={style.question}>What to Bring?</div>
          <div className={style.answer}>
            We have a 45qt cooler with ice on board for your drinks. Plan to
            bring sunscreen, towels, and clothing that you don't care about
            getting wet.
          </div>
          <div className={style.question}>
            What if we have more than 13 people? Is there an option for another
            boat?
          </div>
          <div className={style.answer}>
            Give us a call!! We can try and organize another boat for your party
            so we will have 2 boats and a capacity of 26 (13 on each boat). Both
            boats will have a Captain so need to worry!
          </div>
          <div className={style.question}>
            Do you have other recommendations at the Beach?
          </div>
          <div className={style.answer}>
            <p>
              Yes we do! As locals we can help you plan the perfect week or
              weekend at the beach! One of the best recommendations we have is
              to contact Dewey Beach Picnics for a beautiful evening on the
              beach with bonfires and charcuterie!
            </p>
            <p>
              Give us a call at <a href="tel:443-299-9964">443-299-9964</a> to
              learn about all the options!
            </p>
            <p>
              We also work very closely with the best Travel Agent at the beach
              Brooke Wahlgren! Give her a call at{" "}
              <a href="tel:302-299-6569">302-299-6569</a> and she can help you
              organize the perfect weekend!
            </p>
          </div>
          <div className={style.question}>Is Water Provided?</div>
          <div className={style.answer}>
            Yes the boat comes with a complimentary cooler with water bottles.
          </div>
          <div className={style.question}>Is there a bathroom?</div>
          <div className={style.answer}>
            No, the boat does not have a bathroom on board. Our Captains plan
            the trip around bathroom stops so don't worry! For Example: On our
            Sunset Cruise we have a public bathroom stop about halfway! For our
            trips that get in the water; no need for a bathroom explanation
            there!
          </div>
          <div className={style.question}>Where do we Park?</div>
          <div className={style.answer}>
            Rehoboth Bay Marina 109 Collins Ave Dewey Beach DE. Pay to park is
            located along the street as you pull in on both sides. We do offer
            Rehoboth Pick up and drop offs by request only.
          </div>

          <div className={style.question}>Where is the boat located?</div>
          <div className={style.answer}>
            At the Rehoboth Bay Marina on the south end of Dewey Beach! The
            Octolounge will be waiting for you there to start your trip.{" "}
            <a
              href="https://www.google.com/maps/place/Rehoboth+Bay+Marina/@38.6880719,-75.0775925,17z/data=!3m1!4b1!4m5!3m4!1s0x89b8b61c8e2c49bb:0x44cd4876d26f5427!8m2!3d38.6880677!4d-75.0754038"
              target="_blank"
            >
              Click here for the Google Maps location.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

Faq.displayName = "Faq";
export { Faq };
