import * as React from "react";
import { Offer } from "./Cruise";
import * as style from "./Cruise.style";

const HourlyContent: React.FC = () => {
  return (
    <>
      <div className={style.subHeader}>
        Our most popular options to choose from are:
      </div>
      <div className={style.details}>
        <span style={{ fontWeight: 700 }}>Sandbar Outing:</span> Massey’s
        Landing sandbar, located in the Rehoboth Bay, is where the locals hang
        out on a hot summer day! Spend the day relaxing in the shallow waters of
        the Bay while enjoying music, games and our selection of floats to
        lounge on. Great fun for children and adults!
      </div>
      <div className={style.details}>
        <span style={{ fontWeight: 700 }}>Bar Hopping:</span> The Octolounge is
        the only boat on the Rehoboth Bay that offers you the opportunity to
        enjoy some bar hopping with your own personal “DD”. Our Captains can
        take you from Lewes to Rehoboth to Longneck for a fun day of hitting all
        of the local hotspots on the water!
      </div>
      <div className={style.details}>
        <span style={{ fontWeight: 700 }}>Happy Hour:</span> It doesn’t get any
        better than pregaming your night on the town out on the water. Book us
        for Happy Hour and we can drop you back off at our slip in downtown
        Dewey!
      </div>
    </>
  );
};

const Hourly: React.FC = () => {
  return (
    <Offer
      title="Hourly Charter"
      description="2, 3, 4 or 8 Hour Options &bull; All Ages &bull; Private Group of Up to 13 People"
      imageUrl="/rehoboth-bay-boat-cruise.png"
      imageAlt="Rehoboth Beach Dewey Beach Charter Boat Cruise"
      children={<HourlyContent />}
    />
  );
};

Hourly.displayName = "Hourly";
export { Hourly };
