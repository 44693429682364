import * as React from "react";
import * as style from "./Footer.style";
import * as Icons from "../Icon";
import { IKImage } from "imagekitio-react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <>
      <div className={style.component}>
        <div className={style.content}>
          <div className={style.header}>Hours</div>
          <div>Monday: 7:00am - 11:00pm</div>
          <div>Tuesday: 7:00am - 11:00pm</div>
          <div>Wednesday: 7:00am - 11:00pm</div>
          <div>Thursday: 7:00am - 11:00pm</div>
          <div>Friday: Call to Book</div>
          <div>Saturday: Call to book</div>
          <div>Sunday: 7:00am - 11:00pm</div>
        </div>
        <div className={style.content}>
          <div className={style.header}>Location</div>
          <div>Rehoboth Bay Marina</div>
          <div>109 Collins Ave</div>
          <div>Dewey Beach, DE 19971</div>
          <div>
            <a className={style.link} href="tel:4432999964" type="tel">
              443-299-9964
            </a>
          </div>
          <div>
            <a
              href="mailto:theoctolounge@gmail.com"
              className={style.link}
              type="email"
            >
              theoctolounge@gmail.com
            </a>
          </div>
        </div>
        <div className={style.content}>
          <Link to="/" className={style.routerLink}>
            <div>Home</div>
          </Link>
          <Link to="/offers" className={style.routerLink}>
            <div>What We Offer</div>
          </Link>
          <Link to="/faq" className={style.routerLink}>
            <div>FAQ</div>
          </Link>
        </div>
        <div className={style.content}>
          <div className={style.header}>Book</div>
          <div>
            <a
              className={style.link}
              rel="noreferrer"
              href="https://fareharbor.com/embeds/book/theoctolounge/?full-items=yes"
            >
              2 Hour Cruise
            </a>
          </div>
          <div>
            <a
              className={style.link}
              rel="noreferrer"
              href="https://fareharbor.com/embeds/book/theoctolounge/?full-items=yes"
            >
              3 Hour Cruise
            </a>
          </div>
          <div>
            <a
              className={style.link}
              rel="noreferrer"
              href="https://fareharbor.com/embeds/book/theoctolounge/?full-items=yes"
            >
              4 Hour Cruise
            </a>
          </div>
          <div>
            <a
              className={style.link}
              rel="noreferrer"
              href="https://fareharbor.com/embeds/book/theoctolounge/?full-items=yes"
            >
              8 Hour Cruise
            </a>
          </div>
          <div>
            <a
              className={style.link}
              rel="noreferrer"
              href="https://fareharbor.com/embeds/book/theoctolounge/?full-items=yes"
            >
              Morning Mimosa Cruise
            </a>
          </div>
          <div>
            <a
              className={style.link}
              rel="noreferrer"
              href="https://fareharbor.com/embeds/book/theoctolounge/?full-items=yes"
            >
              Sunset Cruise
            </a>
          </div>
          <div>
            <a
              className={style.link}
              rel="noreferrer"
              href="https://fareharbor.com/embeds/book/theoctolounge/?full-items=yes"
            >
              Dinner Cruise
            </a>
          </div>
          <div>
            <a
              className={style.link}
              rel="noreferrer"
              href="https://fareharbor.com/embeds/book/theoctolounge/?full-items=yes"
            >
              History &amp; Wildlife Tour
            </a>
          </div>
        </div>
        <div className={style.logoIcons}>
          <IKImage
            alt="The Octolounge Logo"
            className={style.logo}
            path="/logos/octolounge-black.png"
          />
          <div className={style.iconContainer}>
            <a
              href="https://www.instagram.com/theoctolounge/"
              target="_blank"
              rel="noopener noreferrer"
              className={style.icon}
            >
              <Icons.Instagram size={50} />
            </a>
            <a
              href="https://www.facebook.com/Octolounge"
              target="_blank"
              rel="noopener noreferrer"
              className={style.icon}
            >
              <Icons.Facebook size={40} />
            </a>
            <a
              href="mailto:theoctolounge@gmail.com"
              className={style.icon}
              type="email"
              target="_blank"
            >
              <Icons.Email width={50} height={38} />
            </a>
            <a
              href="https://g.page/r/CaLKJ-UYvONbEBA"
              target="_blank"
              className={style.icon}
            >
              <Icons.Google size={40} />
            </a>
          </div>
        </div>
      </div>
      <div className={style.secondFooter}>
        <div className={style.copyright}>
          &copy; 2022 The Octolounge. All Rights Reserved{" "}
        </div>
        <div className={style.allCapsWrapper}>
          <a href="https://www.allcapstechnology.com/" target="_blank">
            <Icons.Brackets size={20} stroke="white" fill="white" />
            <div style={{ paddingLeft: 10 }}>Powered By ALL CAPS</div>
          </a>
        </div>
      </div>
    </>
  );
};

Footer.displayName = "Footer";
export { Footer };
