import * as React from "react";
import * as style from "./LearnMore.style";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../../utility";
import { Button, Icon } from "../../../components";
import { Link } from "react-router-dom";
import dinnerCruise from "../../../../public/assets/dinner-cruise.jpg";
import sunsetCruise from "../../../../public/assets/sunset-cruise.jpg";
import hourlyCruise from "../../../../public/assets/hourly-cruise.jpg";

const LearnMore: React.FC = () => {
  const display = hooks.useDisplayContext();
  return (
    <div className={style.component}>
      <div className={style.titleContainer}>
        <IKImage
          className={style.titleImage}
          alt="rehoboth bay water"
          path="/rehoboth-bay-water.png"
          height={display.isMobile ? 75 : 123}
        />
        <div className={style.title}>Learn More...</div>
      </div>
      <div className={style.contentContainer}>
        <div className={style.cruises}>
          <div className={style.cruise}>
            <div className={style.cruiseTitle}>Hourly Cruise</div>
            <div className={style.cruiseDetails}>
              Choose a time slot and enjoy the time how you choose, in the
              Rehoboth Bay or across the Lewes Canal
            </div>
            <ul className={style.bullets}>
              <li className={style.hourlyBullet}>
                2 hour: <span style={{ fontWeight: 500 }}>$450</span>
              </li>
              <li className={style.hourlyBullet}>
                3 hour:{" "}
                <span style={{ fontWeight: 500, marginRight: 0 }}>$550</span>
              </li>
              <li className={style.hourlyBullet}>
                4 hour: <span style={{ fontWeight: 500 }}>$650</span>
              </li>
            </ul>
            <div className={style.imageContainer}>
              <img
                className={style.image}
                alt="dewey rehoboth beach hourly cruise"
                src={hourlyCruise}
              />
            </div>
            <Link to="/hourly" className={style.link}>
              <Button dark={true} rounded={false}>
                Dive In
              </Button>
            </Link>
          </div>
          <div className={style.cruise}>
            <div className={style.cruiseTitle}>Morning Mimosa Cruise</div>
            <div className={style.cruiseDetails}>
              Enjoy the calmness of the bay in the morning time. Drinks and
              breakfast are recommended!
            </div>
            <ul className={style.shortList}>
              <li className={style.bullet}>
                2 hour morning cruise:{" "}
                <span style={{ fontWeight: 500 }}>$425</span>
              </li>
            </ul>
            <div className={style.imageContainer}>
              <IKImage
                className={style.image}
                alt="dewey bachelorette party booze cruise"
                path="/dewey-beach-bachelorette-party-booze-cruise.png"
              />
            </div>
            <Link to="/morning" className={style.link}>
              <Button dark={true} rounded={false}>
                Dive In
              </Button>
            </Link>
          </div>
          <div className={style.cruise}>
            <div className={style.cruiseTitle}>History &amp; Wildlife Tour</div>
            <div className={style.cruiseDetails}>
              Informational and educational tour along the Southern Delaware
              waterways with one of our local captains!
            </div>
            <ul className={style.shortList}>
              <li className={style.bullet}>
                2 hour cruise: <span style={{ fontWeight: 500 }}>$500</span>
              </li>
            </ul>
            <div className={style.imageContainer}>
              <IKImage
                className={style.image}
                alt="rehoboth beach historical cruise"
                path="/rehoboth-beach-historical-cruise.png"
              />
            </div>
            <div>
              <Link to="/history" className={style.link}>
                <Button dark={true} rounded={false}>
                  Dive In
                </Button>
              </Link>
            </div>
          </div>
          <div className={style.cruise}>
            <div className={style.cruiseTitle}>Sunset Cruise</div>
            <div className={style.cruiseDetails}>
              Enjoy a beautiful evening with friends, watching the sunset over
              the Rehoboth Beach waters
            </div>
            <ul className={style.shortList}>
              <li className={style.bullet}>
                2 hour sunset cruise:{" "}
                <span style={{ fontWeight: 500 }}>$425</span>
              </li>
            </ul>
            <div className={style.imageContainer}>
              <img
                className={style.image}
                alt="dewey beach sunset bay cruise"
                src={sunsetCruise}
              />
            </div>
            <Link to="/sunset" className={style.link}>
              <Button dark={true} rounded={false}>
                Dive In
              </Button>
            </Link>
          </div>
          <div className={style.cruise}>
            <div className={style.cruiseTitle}>Dinner Cruise</div>
            <div className={style.cruiseDetails}>
              Enjoy a beautiful evening with friends, watching the sunset over
              the Rehoboth Beach waters
            </div>
            <ul className={style.shortList}>
              <li className={style.bullet}>
                2 hour sunset cruise:{" "}
                <span style={{ fontWeight: 500 }}>$525</span>
              </li>
            </ul>
            <div className={style.imageContainer}>
              <img
                className={style.image}
                style={{ maxHeight: 300 }}
                alt="dewey beach dinner cruise"
                src={dinnerCruise}
              />
            </div>
            <Link to="/sunset" className={style.link}>
              <Button dark={true} rounded={false}>
                Dive In
              </Button>
            </Link>
          </div>
        </div>
        {!display.isMobile && (
          <Icon.Squiggles
            className={style.squiggles}
            fill={"transparent"}
            width={display.windowWidth > 1400 ? 1400 : display.windowWidth}
            height={500}
          />
        )}
        {!display.isMobile && (
          <Icon.Squiggles
            className={style.squiggles2}
            fill={"transparent"}
            width={display.windowWidth > 1400 ? 1400 : display.windowWidth}
            height={500}
          />
        )}
        <div className={style.contentOverlay} />
      </div>
    </div>
  );
};

LearnMore.displayName = "LearnMore";
export { LearnMore };
