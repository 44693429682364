import * as React from "react";
import { Offer } from "./Cruise";
import * as style from "./Cruise.style";
import { Helmet } from "react-helmet";

const SunsetContent: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Enjoy the beautiful sunset over the Rehoboth Bay from the Octolounge with this sunset cruise. Book Now!"
        />
        <title>Dewey Beach and Rehoboth Beach Sunset Cruise</title>
      </Helmet>
      <div className={style.subHeader}>How it goes:</div>
      <div className={style.details}>
        The summer sunset on the Rehoboth Bay is one of the most beautiful
        sights in Delaware. Grab your friends and let our Captain show you the
        best views on the Rehoboth Bay with an evening Sunset Cruise.
      </div>
      <div className={style.details}>
        Rent out the entire boat for your friends and family and get a front row
        seat to watch the sun dip below the horizon.
      </div>
    </>
  );
};

const Sunset: React.FC = () => {
  return (
    <Offer
      title="Sunset Cruise"
      description="2 Hours &bull; All Ages &bull; Private Group of Up to 13 People"
      imageUrl="/dewey-booze-cruise-lg.png"
      imageAlt="Dewey Beach Booze Cruise"
      children={<SunsetContent />}
    />
  );
};

Sunset.displayName = "Sunset";
export { Sunset };
