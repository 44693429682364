import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    padding: "25px 15px",
    backgroundColor: Style.colors.white4,
    fontFamily: Style.text.font.main,
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/Octolounge/dewey-beach-charter-boat-waves.png)",
    backgroundSize: "cover",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: "25px 85px",
    }
  )
);

export const title = s({
  marginBottom: 20,
  textAlign: "center",
  color: Style.colors.tertiary,
  fontSize: 44,
  fontFamily: Style.text.font.fifth,
});

export const description = s({
  fontSize: 18,
  marginBottom: 15,
  textAlign: "center",
});

export const content = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      marginBottom: 80,
    }
  )
);

export const section = s(
  {
    flex: 1,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      $nest: {
        "&:last-child": {
          paddingLeft: 40,
        },
      },
    }
  )
);

export const image = s(
  {
    width: "100%",
    marginBottom: 5,
    borderRadius: 4,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: "50%",
      marginBottom: 0,
    }
  )
);

export const subHeader = s({
  fontWeight: 700,
  fontSize: 20,
  marginBottom: 10,
});

export const details = s({
  fontSize: Style.text.fontSize.small,
  lineHeight: "24px",
  marginBottom: 15,
  textAlign: "left",
});

export const link = s({
  textDecoration: "none",
});

export const bookButton = s(
  {
    fontSize: Style.text.fontSize.smallMed,
    backgroundColor: Style.colors.primary,
    borderRadius: 5,
    padding: "10px 15px",
    textTransform: "uppercase",
    transition: ".2s ease-in",
    color: Style.text.color.inverse,
    textDecoration: "none",
    width: "fit-content",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    $nest: {
      "&:hover": {
        backgroundColor: Style.colors.secondary,
      },
    },
    cursor: "pointer",
    marginBottom: 20,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginBottom: 0,
      fontSize: Style.text.fontSize.medium,
    }
  )
);

export const seeMore = s({
  background: "transparent",
  color: Style.text.color.main,
  border: "1px solid black",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.black1,
      color: Style.text.color.inverse,
    },
  },
});
