import * as React from "react";
import * as style from "./MobileTitle.style";

const MobileTitle: React.FC = () => {
  return (
    <div className={style.component}>
      <div className={style.content}>
        <div className={style.title}>Private Boat Rentals</div>
        <div className={style.border} />
        <div className={style.details}>
          No vacation is complete without a day on the water!
        </div>
        <div className={style.smallDetails}>
          Rehoboth Beach - Dewey Beach - Lewes
        </div>
      </div>
    </div>
  );
};

MobileTitle.displayName = "MobileTitle";
export { MobileTitle };
